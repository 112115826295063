import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton';
import { ISiteButtonProps } from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ShinyButtonInvertedButtonSkin: React.FC<SkinButtonProps> = ({
  wrapperProps,
  linkProps,
  elementType,
  label,
}) => {
  return (
    <div {...wrapperProps} className={skinsStyle.ShinyButtonInverted}>
      <SiteButtonContent
        linkProps={linkProps}
        elementType={elementType}
        className={skinsStyle.link}
      >
        <div className={skinsStyle.labelwrapper}>
          <span className={skinsStyle.label}>{label}</span>
        </div>
      </SiteButtonContent>
    </div>
  );
};

const ShinyButtonInvertedButton: React.FC<Omit<
  ISiteButtonProps,
  'skin'
>> = props => <SiteButton {...props} skin={ShinyButtonInvertedButtonSkin} />;

export default ShinyButtonInvertedButton;
